// Note: Requires flexslider 2, buddy.
jQuery(document).ready(function($) {

	$('.post-nav-left-sec .flexslider').flexslider({
			animation: "fade",
			controlNav: true,
			directionNav: false,
		});

    $('.post-type-nav .post-nav-right-sec .post-cont .post-info span').hover(function() {
        var id = $(this).attr('data-tab'); 
        // console.log("id is", id);
        $(".post-type-nav .post-nav-left-sec .flexslider").each(function(){
            // console.log("flexslider id is", id);
            $(this).hide();
            
            if($(this).attr('data-tab') == id) {
                $(this).show();
            }
        });
    });

	// $('.post-nav-right-sec .post-info span').click(function(e) {
 //        e.preventDefault();
 //        $(this).parent().siblings().find('span').removeClass('active');
 //        $(this).addClass('active');
 //        if ($(this).parent().siblings().find('.cont').is(':visible')){
 //        	$(this).parent().siblings().find('.cont').slideUp();
 //        }
 //        $(this).parent().find('.cont').slideToggle();
 //        var id = $(this).attr('data-tab'); 
 //        console.log("id is", id);
 //        $(".post-nav-left-sec .flexslider").each(function(){
 //            console.log("flexslider id is", id);
            
 //                $(this).hide();
            
 //            if($(this).attr('data-tab') == id) {
 //                $(this).show();
 //            }
 //        });

        // $(this).parent().find('.cont').addClass('active');
        // $(this).parent().siblings().find('.cont').removeClass('active');
    // });
}); /* end of as page load scripts */